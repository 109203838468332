<template>
  <div class="qrcode-cnt">
    <header class="inner-head">
      <div class="left-col">
        <span class="icon-btn" @click="$router.go(-1)">
          <BackIcon />
        </span>
        <h1>QR Code</h1>
      </div>
      <div class="rgt-col">
        <span class="icon-btn">
          <download-icon />
        </span>
      </div>
    </header>

    <section class="mid-cnt">
      <div class="daywise-qrcodebox">
        <div class="qrday-title">
          <div class="optionbox">
            <div class="input_radicol">
              <label class="input-check">
                <input type="checkbox" name="daywise" />
                <div class="daytype"><span>Today, 10 Mar 2021</span></div>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <ul class="qrcode-list">
          <li>
            <div class="optionbox">
              <div class="input_radicol">
                <label class="input-check">
                  <input type="checkbox" name="daywisecode" />
                  <div class="qr-card">
                    <span class="code-icon">
                      <img src="@/assets/images/qr.svg" alt="" />
                    </span>
                    <div class="info-item">
                      <span class="txt-id">#675322</span>
                      <h2>लाइक्रा सिल्क साड़ी</h2>
                      <span class="mobile-num">+91 9876543210</span>
                      <span class="txt-address"
                        >A-123 Sector-62 Noida, 201301</span
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </li>
          <li>
            <div class="optionbox">
              <div class="input_radicol">
                <label class="input-check">
                  <input type="checkbox" name="daywisecode" />
                  <div class="qr-card">
                    <span class="code-icon">
                      <img src="@/assets/images/qr.svg" alt="" />
                    </span>
                    <div class="info-item">
                      <span class="txt-id">#675322</span>
                      <h2>लाइक्रा सिल्क साड़ी</h2>
                      <span class="mobile-num">+91 9876543210</span>
                      <span class="txt-address"
                        >A-123 Sector-62 Noida, 201301</span
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </li>

          <li>
            <div class="optionbox">
              <div class="input_radicol">
                <label class="input-check">
                  <input type="checkbox" name="daywisecode" />
                  <div class="qr-card">
                    <span class="code-icon">
                      <img src="@/assets/images/qr.svg" alt="" />
                    </span>
                    <div class="info-item">
                      <span class="txt-id">#675322</span>
                      <h2>लाइक्रा सिल्क साड़ी</h2>
                      <span class="mobile-num">+91 9876543210</span>
                      <span class="txt-address"
                        >A-123 Sector-62 Noida, 201301</span
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="daywise-qrcodebox">
        <div class="qrday-title">
          <div class="optionbox">
            <div class="input_radicol">
              <label class="input-check">
                <input type="checkbox" name="daywise" />
                <div class="daytype"><span>09 Mar 2021</span></div>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <ul class="qrcode-list">
          <li>
            <div class="optionbox">
              <div class="input_radicol">
                <label class="input-check">
                  <input type="checkbox" name="daywisecode" />
                  <div class="qr-card">
                    <span class="code-icon">
                      <img src="@/assets/images/qr.svg" alt="" />
                    </span>
                    <div class="info-item">
                      <span class="txt-id">#675322</span>
                      <h2>लाइक्रा सिल्क साड़ी</h2>
                      <span class="mobile-num">+91 9876543210</span>
                      <span class="txt-address"
                        >A-123 Sector-62 Noida, 201301</span
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </li>
          <li>
            <div class="optionbox">
              <div class="input_radicol">
                <label class="input-check">
                  <input type="checkbox" name="daywisecode" />
                  <div class="qr-card">
                    <span class="code-icon">
                      <img src="@/assets/images/qr.svg" alt="" />
                    </span>
                    <div class="info-item">
                      <span class="txt-id">#675322</span>
                      <h2>लाइक्रा सिल्क साड़ी</h2>
                      <span class="mobile-num">+91 9876543210</span>
                      <span class="txt-address"
                        >A-123 Sector-62 Noida, 201301</span
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </li>

          <li>
            <div class="optionbox">
              <div class="input_radicol">
                <label class="input-check">
                  <input type="checkbox" name="daywisecode" />
                  <div class="qr-card">
                    <span class="code-icon">
                      <img src="@/assets/images/qr.svg" alt="" />
                    </span>
                    <div class="info-item">
                      <span class="txt-id">#675322</span>
                      <h2>लाइक्रा सिल्क साड़ी</h2>
                      <span class="mobile-num">+91 9876543210</span>
                      <span class="txt-address"
                        >A-123 Sector-62 Noida, 201301</span
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import BackIcon from '@/components/svgIcons/BackIcon';
import DownloadIcon from '@/components/svgIcons/DownloadIcon';
export default {
  name: 'QrCodeDownloadList',
  data() {
    return {};
  },
  components: {
    BackIcon,
    DownloadIcon,
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/qrcode-downloadlist.scss';
</style>
